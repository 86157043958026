<template>
  <div>
    <Menubar class="p-mb-3" :model="items"/>
  </div>
  <router-view/>
</template>


<script>
import Menubar from "primevue/menubar/Menubar";

export default {
  components: {
    Menubar: Menubar
  },
  mounted() {
    this.items = [
      {
        label: 'Главная',
        to   : "/",
      },
      {
        label: 'Вебинары',
        items: [
          {label: 'Просмотр', to: "/webinar"},
          {label: 'Создать', to: "/webinar/create"}
        ],
        visible: () => this.$auth.check(),
      },
      {
        label: 'Войти',
        to: '/auth/logout',
        visible: () => !this.$auth.check(),
      },
      {
        label: 'Выйти',
        to: '/auth/logout',
        visible: () => this.$auth.check(),
      }
    ];
  },
  data() {
    return {
      items: []
    }
  },
}

</script>