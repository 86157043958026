"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bearer = {
    request: function (req, rawToken) {
        var token = JSON.parse(rawToken);
        this.drivers.http.setHeaders.call(this, req, {
            Authorization: 'Bearer ' + token.access_token
        });
    },
    response: function (res) {
        return res.data.isOk ? JSON.stringify(res.data) : null;
    }
};
exports.default = bearer;
