"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Bearer_js_1 = require("../auth/drivers/auth/Bearer.js");
var axios_1_x_esm_js_1 = require("@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js");
var vue_router_2_x_esm_js_1 = require("@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js");
var google_esm_js_1 = require("@websanova/vue-auth/dist/drivers/oauth2/google.esm.js");
var facebook_esm_js_1 = require("@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js");
var vue_auth_1 = require("@websanova/vue-auth");
var axios_1 = require("axios");
var router_1 = require("../router");
var auth = vue_auth_1.createAuth({
    plugins: {
        http: axios_1.default,
        router: router_1.default
    },
    drivers: {
        http: axios_1_x_esm_js_1.default,
        auth: Bearer_js_1.default,
        router: vue_router_2_x_esm_js_1.default,
        oauth2: {
            google: google_esm_js_1.default,
            facebook: facebook_esm_js_1.default,
        }
    },
    options: {
        rolesKey: 'scopes',
        authRedirect: { path: '/auth/login' },
        forbiddenRedirect: { path: '/403' },
        notFoundRedirect: { path: '/404' },
        stores: ['storage'],
        // Http
        loginData: {
            url: process.env.VUE_APP_API_URL + '/api/v1/auth/login',
            method: 'POST',
            redirect: '/',
            fetchUser: true,
            staySignedIn: true
        },
        logoutData: {
            redirect: '/',
            makeRequest: false
        },
        fetchData: { url: process.env.VUE_APP_API_URL + '/api/v1/auth/user', method: 'GET', enabled: true },
        refreshData: {
            url: process.env.VUE_APP_API_URL + '/api/v1/auth/refresh',
            method: 'POST',
            enabled: false,
            interval: 30,
        },
        parseUserData: function (res) { return res || {}; }
    }
});
auth.__proto__.refresh = function () {
    this.options.refreshData.data = {
        refresh_token: JSON.parse(auth.token()).refresh_token
    };
    return auth.drivers.http.http.call(auth, this.options.refreshData);
};
exports.default = auth;
