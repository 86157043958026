import {createRouter, createWebHistory} from 'vue-router'
import Home                             from '../views/Home.vue'

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: Home
    },
    {
        path     : '/auth/login',
        name     : 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/auth/LoginComponent'),
        meta     : {auth: false}
    },
    {
        path     : '/auth/logout',
        name     : 'logout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/auth/LogoutComponent'),
        meta     : {auth: true}
    },
    {
        path     : '/webinar/create',
        name     : 'webinar-create',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/webinar/WebinarCreate'),
        meta     : {auth: {roles: 'Webinars_Administrator'}}
    },
    {
        path     : '/webinar',
        name     : 'webinar',
        component: () => import(/* webpackChunkName: "about" */ '@/components/webinar/WebinarListComponent'),
        meta     : {auth: {roles: 'Webinars_Administrator'}}
    },
    {
        path     : "/403",
        component: () => import(/* webpackChunkName: "about" */ '@/components/Forbidden'),
    },
    {
        path     : "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "about" */ '@/components/NotFound'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router
